import * as constants from "./constants.js";
import * as fieldIds from "./fieldIds.js";
import { SUBSURFACE_DRIP } from "./irrigationSystemIds.js";
import * as numberFormatters from "./numberFormatters.js";
import * as settingIds from "./settingIds.js";
import _ from "lodash";

const fields = [
  {
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    combine: (year) => _.meanBy(year, fieldIds.DF_DAY),
    id: fieldIds.DF_DAY,
    isPositiveChangeBetter: () => true,
    isRequiredByClient: true,
    isScalable: false,
    name: "Harvest day",
    parse: (row) => _.toNumber(row.day),
  },
  {
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    combine: (year) => _.meanBy(year, fieldIds.DF_REAL_DELAY_FOR_KILL),
    id: fieldIds.DF_REAL_DELAY_FOR_KILL,
    isPositiveChangeBetter: () => true,
    isRequiredByClient: true,
    isScalable: false,
    name: "Gap between last harvest and killing",
    parse: (row) => _.toNumber(row.realDelayForKill),
  },
  {
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    combine: (year) => _.meanBy(year, fieldIds.DF_SUGAR_HARVEST_DELAY),
    id: fieldIds.DF_SUGAR_HARVEST_DELAY,
    isPositiveChangeBetter: () => true,
    isRequiredByClient: true,
    isScalable: false,
    name: "Delay in harvest",
    parse: (row) => _.toNumber(row.sugarHarvDelay),
  },
  {
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    combine: (year) => _.meanBy(year, fieldIds.DF_SUGAR_PLANT_DELAY),
    id: fieldIds.DF_SUGAR_PLANT_DELAY,
    isPositiveChangeBetter: () => true,
    isRequiredByClient: true,
    isScalable: false,
    name: "Delay in planting",
    parse: (row) => _.toNumber(row.sugarPlantDelay),
  },
  {
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    combine: (year) => _.meanBy(year, fieldIds.DF_BIOMASS),
    description: "Total above-ground dry biomass.",
    format: numberFormatters.weightFromKg,
    icon: "fa-solid fa-cubes-stacked",
    id: fieldIds.DF_BIOMASS,
    isPositiveChangeBetter: () => true,
    isRequiredByClient: true,
    isScalable: true,
    name: "Total aboveground biomass",
    parse: (row) => _.toNumber(row.biomass) / 100,
    unit: "kg",
  },
  {
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    combine: (year) => _.meanBy(year, fieldIds.DF_BIOMASS_GREEN),
    description: "Green above-ground dry biomass.",
    format: numberFormatters.weightFromKg,
    icon: "fa-solid fa-cubes-stacked",
    id: fieldIds.DF_BIOMASS_GREEN,
    isPositiveChangeBetter: () => true,
    isRequiredByClient: true,
    isScalable: true,
    name: "Green aboveground biomass",
    parse: (row) => _.toNumber(row.green_biomass) / 100,
    unit: "kg",
  },
  {
    id: fieldIds.DF_BURNT_DM,
    parse: (row) => _.toNumber(row.burntDM),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_BURNT_DM),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    shift: -1,
  },
  {
    id: fieldIds.DF_BURNT_ORG_C,
    parse: (row) => _.toNumber(row.burntOrgC) / 1000,
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_BURNT_ORG_C),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    shift: -1,
    unit: "t",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromTonne,
    name: "Surface residue C burnt",
  },
  {
    id: fieldIds.DF_BURNT_ORG_N,
    parse: (row) => _.toNumber(row.burntOrgN),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_BURNT_ORG_N),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    shift: -1,
    unit: "kg",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromKg,
    name: "Surface residue N burnt",
  },
  {
    id: fieldIds.DF_CANE_FW,
    parse: (row) => _.toNumber(row.canefw),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_CANE_FW),
    isScalable: true,
    isPositiveChangeBetter: () => true,
    icon: "fa-solid fa-seedling",
    unit: "t",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromTonne,
    name: "Cane yield",
  },
  {
    id: fieldIds.DF_CANE_WEIGHT,
    parse: (row) => (_.toNumber(row.cane_wt) * 10) / 1000,
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_CANE_WEIGHT),
    isScalable: true,
    isPositiveChangeBetter: () => true,
    icon: "fa-solid fa-seedling",
    unit: "t",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromTonne,
    name: "Cane dry yield",
  },
  {
    id: fieldIds.DF_CROP_CYCLE,
    parse: (row) => _.toNumber(row.cropCycle),
    filter: (row) => row[fieldIds.DF_CROP_CYCLE] !== 1,
    isRequiredByClient: false,
  },
  {
    id: fieldIds.DF_GPWUI,
    parse: (row) => _.toNumber(row.GPWUI),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_GPWUI),
    isScalable: true,
    isPositiveChangeBetter: () => true,
    unit: "t/ML",
    icon: "fa-solid fa-magnifying-glass-dollar",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightPerMegaLiterFromTonne,
    name: "Gross Production Water Use Index",
    description:
      "Gross Production Water Use Index: cane yield / (irrigation water + effective rainfall).",
  },
  {
    id: fieldIds.DF_IRRIGATION_AMOUNT,
    parse: (row) => _.toNumber(row.IrrigAmt),
    isRequiredByClient: true,
    isScalable: true,
  },
  {
    id: fieldIds.DF_IRRIGATION_NUMBER,
    parse: (row) => _.toNumber(row.irrigNum),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_IRRIGATION_NUMBER),
    isPositiveChangeBetter: () => undefined,
    unit: " times",
    icon: "fa-solid fa-calendar-days",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.times,
    name: "Number of irrigations",
  },
  {
    id: fieldIds.DF_NUE,
    parse: (row) => _.toNumber(row.NUE),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_NUE),
    isScalable: false,
    isPositiveChangeBetter: () => true,
    unit: "t/kg",
    icon: "fa-solid fa-magnifying-glass-dollar",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightPerKilogramFromTonne,
    name: "N use efficiency",
    description: "Cane yield / N applied.",
  },
  {
    id: fieldIds.DF_NUPE,
    parse: (row) => _.toNumber(row.canefw) / _.toNumber(row.totalNUptake),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_NUPE),
    isScalable: false,
    isPositiveChangeBetter: () => true,
    unit: "t/kg",
    icon: "fa-solid fa-magnifying-glass-dollar",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightPerKilogramFromTonne,
    name: "N uptake efficiency",
    description: "Cane yield / N uptake.",
  },
  {
    id: fieldIds.DF_TE,
    parse: (row) =>
      (_.toNumber(row.canefw) / _.toNumber(row.totalTransp)) * 100,
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TE),
    isScalable: false,
    isPositiveChangeBetter: () => true,
    unit: "t/ML",
    icon: "fa-solid fa-gauge",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightPerMegaLiterFromTonne,
    name: "Transpiration efficiency",
    description: "Cane yield / crop transpiration.",
  },
  {
    id: fieldIds.DF_PLANTING,
    parse: (row) => row.Planting,
    filter: (row, settings) =>
      row[fieldIds.DF_PLANTING] === settings[settingIds.PLANTING],
    isRequiredByClient: false,
  },
  {
    id: fieldIds.DF_RATOON,
    parse: (row) => _.toNumber(row.ratoon_no),
    filter: (row, settings) => {
      if (settings[settingIds.RATOON] === "ALL") {
        return row[fieldIds.DF_RATOON] >= 0;
      }
      return settings[settingIds.RATOON] === row[fieldIds.DF_RATOON];
    },
    isRequiredByClient: false,
  },
  {
    id: fieldIds.DF_SUCROSE_WEIGHT,
    parse: (row) => _.toNumber(row.sucrose_wt) / 100,
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_SUCROSE_WEIGHT),
    isScalable: true,
    isPositiveChangeBetter: () => true,
    unit: "t",
    icon: "fa-solid fa-cubes-stacked",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromTonne,
    name: "Sucrose yield",
  },
  {
    id: fieldIds.DF_SUGAR_HARVEST_YEAR,
    parse: (row) => _.toNumber(row.year),
    filter: (row, settings) =>
      row[fieldIds.DF_SUGAR_HARVEST_YEAR] <=
        settings[settingIds.HARVEST_YEARS].end &&
      row[fieldIds.DF_SUGAR_HARVEST_YEAR] >=
        settings[settingIds.HARVEST_YEARS].start,
    isRequiredByClient: true,
  },
  {
    id: fieldIds.DF_SUGAR_PLANT_YEAR,
    parse: (row) => _.toNumber(row.sugarPlantYear),
    filter: (row) => row[fieldIds.DF_SUGAR_PLANT_YEAR] >= 0,
    isRequiredByClient: false,
  },
  {
    id: fieldIds.DF_SUGAR_STATUS,
    parse: (row) => row.sugarStatus,
    filter: (row) => row[fieldIds.DF_SUGAR_STATUS] === "alive",
    isRequiredByClient: false,
  },
  {
    id: fieldIds.DF_SURFACE_OM_WT,
    parse: (row) => _.toNumber(row.surfaceom_wt) / 1000,
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_SURFACE_OM_WT),
    isScalable: true,
    isPositiveChangeBetter: () => true,
    unit: "t",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromTonne,
    name: "Surface residue DM at harvest",
  },
  {
    id: fieldIds.DF_TILLAGE,
    parse: (row) => row.Tillage,
    isRequiredByClient: false,
  },
  {
    id: fieldIds.DF_TOTAL_CO2,
    parse: (row) => _.toNumber(row.totalCO2),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_CO2),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg CO₂-e",
    icon: "fa-solid fa-smog",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightOfCarbonDioxideFromKg,
    name: "CO₂ emission from soil",
  },
  {
    id: fieldIds.DF_TOTAL_DRAINAGE,
    parse: (row) => _.toNumber(row.totalDrain) / 100,
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_DRAINAGE),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "ML",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.megaLiters,
    name: "Deep drainage",
  },
  {
    id: fieldIds.DF_TOTAL_EVAPORATION,
    parse: (row) => _.toNumber(row.totalEvap) / 100,
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_EVAPORATION),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "ML",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.megaLiters,
    name: "Soil evaporation",
  },
  {
    id: fieldIds.DF_TOTAL_EVAPOTRANSPIRATION,
    parse: (row) => _.toNumber(row.totalEvapTr) / 100,
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_EVAPOTRANSPIRATION),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "ML",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.megaLiters,
    name: "Evapotranspiration",
  },
  {
    id: fieldIds.DF_TOTAL_IRRIGATION,
    parse: (row) => _.toNumber(row.totalIrrig) / 100,
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_IRRIGATION),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "ML",
    icon: "fa-solid fa-water",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.megaLiters,
    name: "Irrigation water applied",
  },
  {
    id: fieldIds.DF_TOTAL_N2O,
    parse: (row) => _.toNumber(row.totalN2O),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_N2O),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromKg,
    name: "N₂O emission from soil",
  },
  {
    id: fieldIds.DF_TOTAL_N_G_CANE,
    parse: (row) => _.toNumber(row.totalNGCane),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_N_G_CANE),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    icon: "fa-solid fa-water",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromKg,
    name: "Harvested cane N content",
  },
  {
    id: fieldIds.DF_TOTAL_N_LOSS,
    parse: (row) => _.toNumber(row.totalNLoss),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_N_LOSS),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    icon: "fa-solid fa-atom",
    changeThreshold: constants.CHANGE_THRESHOLD_SMALL,
    format: numberFormatters.weightFromKg,
    name: "N loss (runoff + leaching + denitrification)",
  },
  {
    id: fieldIds.DF_TOTAL_N_POL,
    parse: (row) => _.toNumber(row.totalNPol),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_N_POL),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    icon: "nitrate",
    changeThreshold: constants.CHANGE_THRESHOLD_SMALL,
    format: numberFormatters.weightFromKg,
    name: "N pollution (runoff + leaching)",
    description:
      "Nitrate (NO₃⁻) is the dominant form of DIN leaving the paddock via runoff and leaching.",
  },
  {
    id: fieldIds.DF_TOTAL_N_UPTAKE,
    parse: (row) => _.toNumber(row.totalNUptake),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_N_UPTAKE),
    isScalable: true,
    isPositiveChangeBetter: () => true,
    unit: "kg",
    icon: "fa-solid fa-atom",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromKg,
    name: "Crop N uptake",
  },
  {
    id: fieldIds.DF_TOTAL_NITRIFICATION,
    parse: (row) => _.toNumber(row.totalNit),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_NITRIFICATION),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    changeThreshold: constants.CHANGE_THRESHOLD_SMALL,
    format: numberFormatters.weightFromKg,
    name: "Nitrification",
  },
  {
    id: fieldIds.DF_TOTAL_NO3_DENITRIFICATION,
    parse: (row) => _.toNumber(row.totalDeNit),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_NO3_DENITRIFICATION),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    icon: "nitrate",
    changeThreshold: constants.CHANGE_THRESHOLD_SMALL,
    format: numberFormatters.weightFromKg,
    name: "Denitrification",
    description:
      "Nitrate (NO₃⁻) is the dominant contributor to denitrification losses.",
  },
  {
    id: fieldIds.DF_TOTAL_NO3_LEACH,
    parse: (row) => _.toNumber(row.totalNO3Leach),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_NO3_LEACH),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    icon: "nitrate",
    changeThreshold: constants.CHANGE_THRESHOLD_SMALL,
    format: numberFormatters.weightFromKg,
    name: "DIN loss via leaching",
    description:
      "Nitrate (NO₃⁻) is the dominant form of DIN leaving the paddock via leaching.",
  },
  {
    id: fieldIds.DF_TOTAL_NO3_RUNOFF,
    parse: (row) => _.toNumber(row.totalNO3Runoff),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_NO3_RUNOFF),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    icon: "nitrate",
    changeThreshold: constants.CHANGE_THRESHOLD_SMALL,
    format: numberFormatters.weightFromKg,
    name: "DIN loss via runoff",
    description:
      "Nitrate (NO₃⁻) is the dominant form of DIN leaving the paddock via runoff",
  },
  {
    id: fieldIds.DF_TOTAL_INITIAL_INORG_N,
    parse: (row) => _.toNumber(row.totalInitInorgN),
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_INITIAL_INORG_N),
    isRequiredByClient: true,
    unit: "kg",
  },
  {
    id: fieldIds.DF_TOTAL_INITIAL_ORG_N,
    parse: (row) => _.toNumber(row.totalInitOrgN),
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_INITIAL_ORG_N),
    isRequiredByClient: true,
    unit: "kg",
  },
  {
    id: fieldIds.DF_TOTAL_INITIAL_SOIL_N,
    parse: (row) => _.toNumber(row.totalInitSoilN),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_INITIAL_SOIL_N),
    isScalable: true,
    isPositiveChangeBetter: () => true,
    unit: "kg",
    icon: "fa-solid fa-cookie",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromKg,
    name: "Soil N at crop start",
    description:
      "Soil N at planting (for plant crops) or after harvest (for ratoons).",
  },
  {
    id: fieldIds.DF_TOTAL_RAIN,
    parse: (row) => _.toNumber(row.totalRain) / 100,
    isRequiredByClient: true,
    isPositiveChangeBetter: () => true,
    unit: "ML",
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_RAIN),
    isScalable: true,
  },
  {
    id: fieldIds.DF_TOTAL_EFF_RAIN,
    parse: (row) => _.toNumber(row.totalEffRain) / 100,
    isRequiredByClient: true,
    isPositiveChangeBetter: () => true,
    unit: "ML",
    icon: "fa-solid fa-cloud-rain",
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_EFF_RAIN),
    isScalable: true,
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.megaLiters,
    name: "Effective rainfall",
  },
  {
    id: fieldIds.DF_TOTAL_RUNOFF,
    parse: (row) => _.toNumber(row.totalRunoff) / 100,
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_RUNOFF),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "ML",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.megaLiters,
    name: "Runoff",
  },
  {
    id: fieldIds.DF_TOTAL_SEDMINENT,
    parse: (row) => _.toNumber(row.totalSediment),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_SEDMINENT),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "t",
    icon: "fa-solid fa-hourglass",
    changeThreshold: constants.CHANGE_THRESHOLD_SMALL,
    format: numberFormatters.weightFromTonne,
    name: "Soil loss",
  },
  {
    id: fieldIds.DF_TOTAL_SOIL_C,
    parse: (row) => _.toNumber(row.totalSoilC) / 1000,
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_SOIL_C),
    isScalable: true,
    isPositiveChangeBetter: () => true,
    unit: "t",
    icon: "fa-solid fa-cookie",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromTonne,
    name: "Soil C at harvest",
  },
  {
    id: fieldIds.DF_TOTAL_SOIL_INORG_N,
    parse: (row) => _.toNumber(row.totalSoilInorgN),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_SOIL_INORG_N),
    isScalable: true,
    isPositiveChangeBetter: () => true,
    unit: "kg",
    icon: "fa-solid fa-cookie",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromKg,
    name: "Soil inorganic N at harvest",
  },
  {
    id: fieldIds.DF_TOTAL_SOIL_ORG_N,
    parse: (row) => _.toNumber(row.totalSoilOrgN),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_SOIL_ORG_N),
    isScalable: true,
    isPositiveChangeBetter: () => true,
    unit: "kg",
    icon: "fa-solid fa-cookie",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromKg,
    name: "Soil organic N at harvest",
  },
  {
    id: fieldIds.DF_TOTAL_SOIL_N,
    parse: (row) => _.toNumber(row.totalSoilN),
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_SOIL_N),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    icon: "fa-solid fa-cookie",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromKg,
    name: "Soil N at harvest",
  },
  {
    id: fieldIds.DF_TOTAL_TRANSPIRATION,
    parse: (row) => _.toNumber(row.totalTransp) / 100,
    isRequiredByClient: true,
    combine: (year) => _.meanBy(year, fieldIds.DF_TOTAL_TRANSPIRATION),
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "ML",
    icon: "fa-solid fa-faucet",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.megaLiters,
    name: "Crop transpiration",
  },
  {
    id: fieldIds.DD_DELTA_SOIL_INORG_N,
    generate: (data) =>
      data[fieldIds.DF_TOTAL_SOIL_INORG_N] -
      data[fieldIds.DF_TOTAL_INITIAL_INORG_N],
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    icon: "fa-solid fa-cookie",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromKg,
    name: "Change in soil inorganic N",
  },
  {
    id: fieldIds.DD_DELTA_SOIL_ORG_N,
    generate: (data) =>
      data[fieldIds.DF_TOTAL_SOIL_ORG_N] -
      data[fieldIds.DF_TOTAL_INITIAL_ORG_N],
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    icon: "fa-solid fa-cookie",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromKg,
    name: "Change in soil organic N",
  },
  {
    id: fieldIds.DD_DELTA_SOIL_N,
    generate: (data) =>
      data[fieldIds.DF_TOTAL_SOIL_N] - data[fieldIds.DF_TOTAL_INITIAL_SOIL_N],
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    icon: "fa-solid fa-cookie",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromKg,
    name: "Change in soil N",
  },
  {
    id: fieldIds.DD_NITROGEN_LOSS_RISK_INDEX,
    generate: (data) => 1 / data[fieldIds.DF_NUE],
    isScalable: false,
    isPositiveChangeBetter: () => false,
    unit: "kg N / t cane",
    icon: "fa-solid fa-atom",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.kilogramNitrogenPerTonneCane,
    name: "Nitrogen Loss Risk Index",
    description: "N applied / cane yield.",
    url: "https://doi.org/10.1016/j.jenvman.2022.115932",
  },
  {
    id: fieldIds.DD_WATER_COST,
    generate: (data, settings) =>
      data[fieldIds.DF_TOTAL_IRRIGATION] * settings[settingIds.WATER_COST],
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "$",
    icon: "fa-solid fa-faucet",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.currency,
    name: "Water cost",
  },
  {
    id: fieldIds.DD_TOTAL_ENERGY_USED,
    generate: (data, settings) => {
      let totalEnergyUsed;
      if (settings[settingIds.IRRIGATION_SYSTEM] === SUBSURFACE_DRIP) {
        totalEnergyUsed =
          data[fieldIds.DF_TOTAL_IRRIGATION] *
          settings[settingIds.DRIP_ENERGY_USED];
      } else {
        totalEnergyUsed =
          data[fieldIds.DF_TOTAL_IRRIGATION] *
          settings[settingIds.SURFACE_ENERGY_USED];
      }
      return totalEnergyUsed;
    },
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kWh",
    icon: "fa-solid fa-plug",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.powerFromKwh,
    name: "Energy use",
  },
  {
    id: fieldIds.DD_TOTAL_ENERGY_COST,
    generate: (data, settings) =>
      data[fieldIds.DD_TOTAL_ENERGY_USED] * settings[settingIds.ENERGY_COST],
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "$",
    icon: "fa-solid fa-bolt",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.currency,
    name: "Energy cost",
  },
  {
    id: fieldIds.DD_SUGAR_VALUE,
    generate: (data, settings) => {
      const sugarValue =
        ((data[fieldIds.DF_CANE_FW] *
          (100 - settings[settingIds.HARVEST_LOSS])) /
          100) *
        (0.009 *
          (settings[settingIds.RELATIVE_CCS] - 4) *
          settings[settingIds.SUGAR_PRICE] +
          settings[settingIds.CANE_PRICE_CONSTANT]);
      return sugarValue;
    },
    isScalable: true,
    isPositiveChangeBetter: () => true,
    unit: "$",
    icon: "fa-solid fa-dollar-sign",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.currency,
    name: "Gross income",
    description:
      "Value of sugarcane produced considering harvest loss (if any).",
  },
  {
    id: fieldIds.DD_TOTAL_IRRIGATION_OPERATION_COST,
    generate: (data, settings) => {
      let cost;
      if (settings[settingIds.IRRIGATION_SYSTEM] === SUBSURFACE_DRIP) {
        cost = settings[settingIds.DRIP_OPERATION_COST];
      } else {
        cost = settings[settingIds.SURFACE_OPERATION_COST];
      }
      cost *= data[fieldIds.DF_IRRIGATION_NUMBER];
      cost /= settings[settingIds.PADDOCK_SIZE];
      return cost;
    },
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "$",
    icon: "fa-solid fa-dollar-sign",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.currency,
    name: "Operation cost",
  },
  {
    id: fieldIds.DD_TOTAL_COST,
    generate: (data) =>
      data[fieldIds.DD_WATER_COST] +
      data[fieldIds.DD_TOTAL_ENERGY_COST] +
      data[fieldIds.DD_TOTAL_IRRIGATION_OPERATION_COST],
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "$",
    icon: "fa-solid fa-dollar-sign",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.currency,
    name: "Total cost",
  },
  {
    id: fieldIds.DD_NET_INCOME,
    generate: (data) =>
      data[fieldIds.DD_SUGAR_VALUE] - data[fieldIds.DD_TOTAL_COST],
    isScalable: true,
    isPositiveChangeBetter: () => true,
    unit: "$",
    icon: "fa-solid fa-dollar-sign",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.currency,
    name: "Net income",
    description: "Gross income - water, energy, and operation costs.",
  },
  {
    id: fieldIds.DD_TOTAL_CH4_BURNT_GHG,
    generate: (data) =>
      data[fieldIds.DF_BURNT_DM] *
      constants.CH4_BURN_FACTOR *
      constants.CH4_CO2E,
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg CO₂-e",
    icon: "fa-solid fa-smog",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightOfCarbonDioxideFromKg,
    name: "CH₄ emission from burnt biomass",
    description: "In CO2 equivalent (CO₂-e).",
  },
  {
    id: fieldIds.DD_TOTAL_PUMP_CO2,
    generate: (data, settings) =>
      (data[fieldIds.DD_TOTAL_ENERGY_USED] * 3600 * settings[settingIds.FUEL]) /
      1e9,
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg CO₂-e",
    icon: "fa-solid fa-smog",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightOfCarbonDioxideFromKg,
    name: "GHG emission from pumping",
    description: "In CO2 equivalent (CO₂-e).",
  },
  {
    id: fieldIds.DD_TOTAL_N2O_GHG,
    generate: (data) => data[fieldIds.DF_TOTAL_N2O] * constants.N2O_CO2E,
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg CO₂-e",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightOfCarbonDioxideFromKg,
    name: "N₂O emission from soil",
    description: "In CO2 equivalent (CO₂-e).",
  },
  {
    id: fieldIds.DD_TOTAL_N2O_BURNT,
    generate: (data) => data[fieldIds.DF_BURNT_DM] * constants.N2O_BURN_FACTOR,
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightFromKg,
    name: "N₂O emission from burnt biomass",
  },
  {
    id: fieldIds.DD_TOTAL_N2O_BURNT_GHG,
    generate: (data) => data[fieldIds.DD_TOTAL_N2O_BURNT] * constants.N2O_CO2E,
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg CO₂-e",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightOfCarbonDioxideFromKg,
    name: "N₂O emission from burnt biomass",
    description: "In CO2 equivalent (CO₂-e).",
  },
  {
    id: fieldIds.DD_TOTAL_N2O_ALL,
    generate: (data) =>
      data[fieldIds.DD_TOTAL_N2O_GHG] + data[fieldIds.DD_TOTAL_N2O_BURNT_GHG],
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg CO₂-e",
    icon: "fa-solid fa-smog",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightOfCarbonDioxideFromKg,
    name: "Nitrous oxide (N₂O)",
    description: "In CO2 equivalent (CO₂-e).",
  },
  {
    id: fieldIds.DD_TOTAL_CO2_E,
    generate: (data) =>
      data[fieldIds.DF_TOTAL_CO2] +
      data[fieldIds.DD_TOTAL_PUMP_CO2] +
      data[fieldIds.DD_TOTAL_N2O_ALL] +
      data[fieldIds.DD_TOTAL_CH4_BURNT_GHG],
    isScalable: true,
    isPositiveChangeBetter: () => false,
    unit: "kg CO₂-e",
    icon: "fa-solid fa-smog",
    changeThreshold: constants.CHANGE_THRESHOLD_LARGE,
    format: numberFormatters.weightOfCarbonDioxideFromKg,
    name: "Total GHG emission",
    description: "In CO2 equivalent (CO₂-e).",
  },
];

export default _.zipObject(
  fields.map(({ id }) => id),
  fields
);
