import ChartBox from "./ChartBox";
import ChartContainer from "./ChartContainer";
import ChartCount from "./ChartCount";
import ChartDiff from "./ChartDiff";
import ChartHeatmap from "./ChartHeatmap";
import ChartPie from "./ChartPie";
import ChartProbability from "./ChartProbability";
import ChartRain from "./ChartRain";
import ChartScatter from "./ChartScatter";
import ChartTimeSeries from "./ChartTimeSeries";
import ChartTreemap from "./ChartTreemap";
import ChartTypeSelector from "./ChartTypeSelector";
import "./Charts.scss";
import ChartsContainer from "./ChartsContainer";
import TableCount from "./TableCount";
import {
  VIEW_BASIC_CHART,
  VIEW_ADVANCED_CHART,
  VIEW_ADVANCED_CHART_TYPES,
  VIEW_BASIC_CHART_TYPES,
  VIEW_EXPERIMENTAL_CHART,
  VIEW_EXPERIMENTAL_CHART_TYPES,
  VIEW_BASIC_TABS,
  VIEW_ADVANCED_TABS,
  VIEW_EXPERIMENTAL_TABS,
} from "./auth";
import * as fieldIds from "./fieldIds";
import fields from "./fields";
import interactions from "./interactions";
import { calculateRelativeChange } from "./utils";
import _ from "lodash";
import PropTypes from "proptypes";
import React from "react";

const defaultChartOptions = {
  permission: VIEW_BASIC_CHART,
  decimalPlaces: 2,
  xLabel: "Harvest year",
};

export const chartTabs = {
  "Cane Yield": {
    permission: VIEW_BASIC_TABS,
    charts: [
      {
        fieldId: fieldIds.DF_CANE_FW,
      },
      {
        permission: VIEW_ADVANCED_CHART,
        fieldId: fieldIds.DF_CANE_WEIGHT,
      },
      {
        permission: VIEW_EXPERIMENTAL_CHART,
        fieldId: fieldIds.DF_SUCROSE_WEIGHT,
      },
      {
        permission: VIEW_EXPERIMENTAL_CHART,
        fieldId: fieldIds.DF_BIOMASS,
      },
      {
        permission: VIEW_EXPERIMENTAL_CHART,
        fieldId: fieldIds.DF_BIOMASS_GREEN,
      },
    ],
  },
  "Water Balance": {
    permission: VIEW_BASIC_TABS,
    charts: [
      {
        fieldId: fieldIds.DF_TOTAL_IRRIGATION,
      },
      {
        fieldId: fieldIds.DF_TOTAL_RUNOFF,
      },
      {
        fieldId: fieldIds.DF_TOTAL_DRAINAGE,
      },
      {
        fieldId: fieldIds.DF_TOTAL_EVAPOTRANSPIRATION,
      },
      {
        fieldId: fieldIds.DF_TOTAL_EFF_RAIN,
      },
      {
        permission: VIEW_ADVANCED_CHART,
        fieldId: fieldIds.DF_TOTAL_TRANSPIRATION,
      },
      {
        permission: VIEW_ADVANCED_CHART,
        fieldId: fieldIds.DF_TOTAL_EVAPORATION,
      },
      {
        permission: VIEW_EXPERIMENTAL_CHART,
        fieldId: fieldIds.DF_IRRIGATION_NUMBER,
      },
    ],
    customCharts: [
      (props) => {
        const data = _.keys(props.data.years).map((year) => ({
          y: props.data.years[year][fieldIds.DF_TOTAL_RAIN].current,
          x: year,
        }));
        const mean = _.get(
          props,
          `data.stats.mean[${fieldIds.DF_TOTAL_RAIN}].current`,
          0
        );
        return (
          <ChartContainer
            title="In-Season rain"
            subtitle={[`Current mean = ${_.round(mean, 1)}`]}
          >
            <ChartRain data={data} mean={mean} isLoading={data.length === 0} />
          </ChartContainer>
        );
      },
    ],
  },
  "N Balance": {
    permission: VIEW_BASIC_TABS,
    charts: [
      {
        fieldId: fieldIds.DF_TOTAL_N_POL,
      },
      {
        fieldId: fieldIds.DF_TOTAL_N_LOSS,
      },
      {
        fieldId: fieldIds.DF_TOTAL_NO3_RUNOFF,
      },
      {
        fieldId: fieldIds.DF_TOTAL_NO3_LEACH,
      },
      {
        fieldId: fieldIds.DF_TOTAL_NO3_DENITRIFICATION,
      },
      {
        permission: VIEW_ADVANCED_CHART,
        fieldId: fieldIds.DF_TOTAL_NITRIFICATION,
      },
      {
        fieldId: fieldIds.DF_TOTAL_N_UPTAKE,
      },
      {
        permission: VIEW_EXPERIMENTAL_CHART,
        fieldId: fieldIds.DF_TOTAL_SOIL_N,
      },
      {
        permission: VIEW_ADVANCED_CHART,
        fieldId: fieldIds.DF_TOTAL_SOIL_INORG_N,
      },
      {
        permission: VIEW_ADVANCED_CHART,
        fieldId: fieldIds.DF_TOTAL_SOIL_ORG_N,
      },
      {
        permission: VIEW_ADVANCED_CHART,
        fieldId: fieldIds.DF_TOTAL_N2O,
      },
      {
        permission: VIEW_ADVANCED_CHART,
        fieldId: fieldIds.DF_BURNT_ORG_N,
      },
      {
        permission: VIEW_ADVANCED_CHART,
        fieldId: fieldIds.DD_TOTAL_N2O_BURNT,
      },
    ],
  },
  "C Balance": {
    permission: VIEW_ADVANCED_TABS,
    charts: [
      {
        fieldId: fieldIds.DF_TOTAL_SOIL_C,
      },
      {
        fieldId: fieldIds.DF_BURNT_ORG_C,
      },
      {
        fieldId: fieldIds.DF_SURFACE_OM_WT,
      },
    ],
  },
  "GHG Emission": {
    permission: VIEW_ADVANCED_TABS,
    charts: [
      {
        fieldId: fieldIds.DD_TOTAL_CO2_E,
      },
      {
        fieldId: fieldIds.DD_TOTAL_N2O_GHG,
      },
      {
        fieldId: fieldIds.DF_TOTAL_CO2,
      },
      {
        fieldId: fieldIds.DD_TOTAL_CH4_BURNT_GHG,
      },
      {
        fieldId: fieldIds.DD_TOTAL_N2O_BURNT_GHG,
      },
      {
        fieldId: fieldIds.DD_TOTAL_PUMP_CO2,
      },
    ],
  },
  Productivity: {
    permission: VIEW_BASIC_TABS,
    charts: [
      {
        fieldId: fieldIds.DF_GPWUI,
      },
      {
        fieldId: fieldIds.DF_NUE,
      },
      {
        permission: VIEW_EXPERIMENTAL_CHART,
        fieldId: fieldIds.DF_NUPE,
      },
      {
        permission: VIEW_ADVANCED_CHART,
        fieldId: fieldIds.DF_TE,
      },
    ],
  },
  Management: {
    permission: VIEW_EXPERIMENTAL_TABS,
    charts: [
      {
        permission: VIEW_EXPERIMENTAL_CHART,
        fieldId: fieldIds.DF_DAY,
      },
      {
        permission: VIEW_EXPERIMENTAL_CHART,
        fieldId: fieldIds.DF_SUGAR_PLANT_DELAY,
      },
      {
        permission: VIEW_EXPERIMENTAL_CHART,
        fieldId: fieldIds.DF_SUGAR_HARVEST_DELAY,
      },
      {
        permission: VIEW_EXPERIMENTAL_CHART,
        fieldId: fieldIds.DF_REAL_DELAY_FOR_KILL,
      },
    ],
  },
};

export const getChartTabIds = (checkPermission) =>
  _.keys(chartTabs).filter((key) => checkPermission(chartTabs[key].permission));

export const generateSubtitleTypeA = (chart, chartData, stats, scenarios) => {
  const field = fields[chart.fieldId];
  const totalCount = _.values(chartData).length;
  const relativeChanges = _.values(chartData).map((values) =>
    calculateRelativeChange(values.new, values.current)
  );
  const increaseCount = relativeChanges.filter(
    (diff) => diff > field.changeThreshold
  ).length;
  const decreaseCount = relativeChanges.filter(
    (diff) => diff < -field.changeThreshold
  ).length;
  const isPositiveChangeBetter = field.isPositiveChangeBetter({ scenarios });
  if (_.isUndefined(isPositiveChangeBetter)) {
    return [
      `New scenario increased in ${increaseCount} out of ${totalCount} seasons`,
      `New scenario decreased in ${decreaseCount} out of ${totalCount} seasons`,
    ];
  }
  const improvedCount = isPositiveChangeBetter ? increaseCount : decreaseCount;
  const worseCount = isPositiveChangeBetter ? decreaseCount : increaseCount;
  return [
    `New scenario is better in ${improvedCount} out of ${totalCount} seasons`,
    `New scenario is worse in ${worseCount} out of ${totalCount} seasons`,
  ];
};

export const generateSubtitleTypeB = (chart, chartData, stats) => {
  if (!_.has(stats, "mean")) {
    return [];
  }
  const totalCount = _.values(chartData).length;
  const mean = _.round(
    calculateRelativeChange(stats.mean.new, stats.mean.current) * 100
  );
  if (mean > 0) {
    return [
      `New scenario increases the ${totalCount}-year average by ${Math.abs(
        mean
      )}%`,
    ];
  }
  if (mean < 0) {
    return [
      `New scenario decreases the ${totalCount}-year average by ${Math.abs(
        mean
      )}%`,
    ];
  }
  return [`New scenario does not change the ${totalCount}-year average`];
};

export const types = {
  "Box plot": {
    component: ChartBox,
    permission: VIEW_ADVANCED_CHART_TYPES,
    generateSubtitle: generateSubtitleTypeB,
  },
  "History chart": {
    component: ChartHeatmap,
    permission: VIEW_BASIC_CHART_TYPES,
    generateSubtitle: generateSubtitleTypeA,
  },
  "Count plot": {
    component: ChartCount,
    permission: VIEW_BASIC_CHART_TYPES,
    generateSubtitle: generateSubtitleTypeA,
  },
  "Count table": {
    component: TableCount,
    permission: VIEW_ADVANCED_CHART_TYPES,
    generateSubtitle: generateSubtitleTypeA,
  },
  "Probability plot": {
    component: ChartProbability,
    permission: VIEW_ADVANCED_CHART_TYPES,
    generateSubtitle: generateSubtitleTypeA,
  },
  "Pie chart": {
    component: ChartPie,
    permission: VIEW_ADVANCED_CHART_TYPES,
    generateSubtitle: generateSubtitleTypeA,
  },
  "Tree map": {
    component: ChartTreemap,
    permission: VIEW_EXPERIMENTAL_CHART_TYPES,
    generateSubtitle: generateSubtitleTypeA,
  },
  "Time series": {
    component: ChartTimeSeries,
    permission: VIEW_EXPERIMENTAL_CHART_TYPES,
    generateSubtitle: generateSubtitleTypeB,
  },
  "Scatter plot": {
    component: ChartScatter,
    permission: VIEW_EXPERIMENTAL_CHART_TYPES,
    generateSubtitle: generateSubtitleTypeB,
  },
  Diff: {
    component: ChartDiff,
    permission: VIEW_EXPERIMENTAL_CHART_TYPES,
    generateSubtitle: generateSubtitleTypeA,
  },
};

export const chartTypeIds = _.keys(types);

export const generateFieldData = (chart, data) => {
  const allChartData = _.mapValues(data, (year) => ({
    new: year[chart.fieldId].new,
    current: year[chart.fieldId].current,
  }));
  const filteredChartData = _.pickBy(
    allChartData,
    (year) => !_.isNull(year.new) && !_.isNull(year.current)
  );
  return filteredChartData;
};

function Charts({
  checkPermission,
  tab,
  data,
  chartType,
  setChartType,
  isLoading,
  showHeader,
  scenarios,
}) {
  const typeOptions = _.keys(types).filter((key) =>
    checkPermission(types[key].permission)
  );
  const Component = types[chartType].component;
  const { generateSubtitle } = types[chartType];
  return (
    <ChartsContainer
      header={
        showHeader && (
          <ChartTypeSelector
            options={typeOptions}
            value={chartType}
            onChange={(value) => {
              interactions.publish({
                category: "Chart type",
                action: "selected",
                label: value,
              });
              setChartType(value);
            }}
          />
        )
      }
    >
      {chartTabs[tab].charts
        .map((item) => _.defaults(item, defaultChartOptions))
        .filter((chart) => checkPermission(chart.permission))
        .map((chart) => {
          const field = fields[chart.fieldId];
          let stats;
          if (data.stats) {
            stats = _.mapValues(data.stats, (stat) => stat[field.id]);
          }
          const chartData = generateFieldData(chart, data.years);
          const subtitle = generateSubtitle(chart, chartData, stats, scenarios);
          return (
            <ChartContainer
              key={field.id}
              title={field.name}
              subtitle={subtitle}
            >
              <Component
                xLabel={chart.xLabel}
                unit={field.isScalable ? `${field.unit}/ha` : field.unit}
                data={chartData}
                isPositiveBetter={field.isPositiveChangeBetter({ scenarios })}
                decimalPlaces={chart.decimalPlaces}
                changeThreshold={field.changeThreshold}
                format={field.format}
                stats={stats}
                isLoading={isLoading}
              />
            </ChartContainer>
          );
        })}
      {_.get(chartTabs[tab], "customCharts", []).map((CustomChart, index) => (
        <CustomChart key={index} data={data} />
      ))}
    </ChartsContainer>
  );
}

Charts.propTypes = {
  checkPermission: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  chartType: PropTypes.string.isRequired,
  setChartType: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showHeader: PropTypes.bool,
  scenarios: PropTypes.shape({}).isRequired,
};

Charts.defaultProps = {
  data: false,
  showHeader: true,
};

export default React.memo(Charts);
