import * as irrigationSystemIds from "./irrigationSystemIds.js";
import _ from "lodash";

export const lowFrequency = {
  value: "LowFreq",
  label: "14-day cycles at peak demand",
  irrigationSystems: [irrigationSystemIds.FURROW],
};

export const mediumFrequency = {
  value: "MediumFreq",
  label: "10-day cycles at peak demand",
  irrigationSystems: [irrigationSystemIds.FURROW],
};

export const highFrequency = {
  value: "HighFreq",
  label: "7-day cycles at peak demand",
  irrigationSystems: [irrigationSystemIds.FURROW],
};

export const swBased30 = {
  value: "SWBased_600_30_15_8_30",
  label: "Irrigate when SWD > 30% PAW",
  irrigationSystems: [
    irrigationSystemIds.SUBSURFACE_DRIP,
    irrigationSystemIds.LPOH_5_0,
    irrigationSystemIds.LPOH_5_10,
    irrigationSystemIds.LPOH_5_20,
    irrigationSystemIds.Traveller_15_0,
    irrigationSystemIds.Traveller_15_10,
    irrigationSystemIds.Traveller_15_25,
  ],
};

export const swBased40 = {
  value: "SWBased_900_40_20_10_40",
  label: "Irrigate when SWD > 40% PAW",
  irrigationSystems: [
    irrigationSystemIds.FURROW,
    irrigationSystemIds.LPOH_5_0,
    irrigationSystemIds.LPOH_5_10,
    irrigationSystemIds.LPOH_5_20,
    irrigationSystemIds.Traveller_15_0,
    irrigationSystemIds.Traveller_15_10,
    irrigationSystemIds.Traveller_15_25,
  ],
};

export const swBased60 = {
  value: "SWBased_900_60_30_15_60",
  label: "Irrigate when SWD > 60% PAW",
  irrigationSystems: [
    irrigationSystemIds.FURROW,
    irrigationSystemIds.LPOH_5_0,
    irrigationSystemIds.LPOH_5_10,
    irrigationSystemIds.LPOH_5_20,
    irrigationSystemIds.Traveller_15_0,
    irrigationSystemIds.Traveller_15_10,
    irrigationSystemIds.Traveller_15_25,
  ],
};

export const swBased70 = {
  value: "SWBased_900_70_70_70_70",
  label: "Irrigate when SWD > 70% PAW",
  irrigationSystems: [
    irrigationSystemIds.FURROW,
    irrigationSystemIds.LPOH_5_0,
    irrigationSystemIds.LPOH_5_10,
    irrigationSystemIds.LPOH_5_20,
    irrigationSystemIds.Traveller_15_0,
    irrigationSystemIds.Traveller_15_10,
    irrigationSystemIds.Traveller_15_25,
  ],
};

export const swBased70late = {
  value: "SWBased_900_70_200_70_70",
  label: "Irrigate when SWD > 70% PAW, delayed start",
  irrigationSystems: [
    irrigationSystemIds.FURROW,
    irrigationSystemIds.LPOH_5_0,
    irrigationSystemIds.LPOH_5_10,
    irrigationSystemIds.LPOH_5_20,
    irrigationSystemIds.Traveller_15_0,
    irrigationSystemIds.Traveller_15_10,
    irrigationSystemIds.Traveller_15_25,
  ],
};

const irrigationFrequencyOptions = [
  lowFrequency,
  mediumFrequency,
  highFrequency,
  swBased30,
  swBased60,
  swBased70,
  swBased70late,
];

export const furrowIrrigationFrequencies = _.map(
  _.filter(
    irrigationFrequencyOptions,
    ({ system }) => system === irrigationSystemIds.FURROW
  ),
  "value"
);
