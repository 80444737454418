import Help from "./Help";
import MapInput from "./Map";
import { CHANGE_BASIC_SETTINGS, CHANGE_ADVANCED_SETTINGS } from "./auth";
import { COLOR_PRIMARY, COLOR_GRAY_300 } from "./colors";
import * as helps from "./helps";
import * as irrigationSystemIds from "./irrigationSystemIds";
import regions from "./regions";
import * as settingIds from "./settingIds";
import weatherStations from "./weatherStations";
import produce from "immer";
import _ from "lodash";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import uniqid from "uniqid";

class Setting {
  isVisible = () => true;

  render = () => null;

  permission = CHANGE_BASIC_SETTINGS;

  dependsOn = [];

  onDependencySettingChange = (values) => values;

  generateUniqueId = () => _.kebabCase(uniqid(`${this.id}_SETTING_`));

  getDependents = () => {
    const dependencies = _.filter(settings, (setting) =>
      _.includes(setting.dependsOn, this.id)
    );
    return _.reverse(
      _.uniq(
        _.reverse(
          _.concat(
            dependencies,
            _.flatten(dependencies.map((setting) => setting.getDependents()))
          )
        )
      )
    );
  };

  onChange = (value, scenarios) =>
    produce(scenarios, (draft) => {
      const dependants = this.getDependents();
      _.toPairs(draft).forEach(([name, scenario]) => {
        scenario.settings[this.id] = value;
        dependants.forEach((dependant) =>
          dependant.onDependencySettingChange(
            scenario.settings,
            scenarios[name].settings,
            name
          )
        );
      });
    });
}

class RegionSetting extends Setting {
  id = settingIds.REGION;

  dependsOn = [settingIds.WEATHER_STATION];

  onDependencySettingChange = (values, previous, name) => {
    const region = _.find(regions, ({ weatherStations }) =>
      _.find(weatherStations, { value: values[settingIds.WEATHER_STATION] })
    );
    values[this.id] = region.id;
    const hasRegionChanged = previous[this.id] !== values[this.id];
    if (hasRegionChanged) {
      values = _.merge(values, region.scenarioDefaults[name]);
    }
    return values;
  };

  isVisible = () => false;
}

class WeatherStationSetting extends Setting {
  id = settingIds.WEATHER_STATION;

  cols = 12;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    const region = regions[values[settingIds.REGION]];
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label d-flex flex-column">
          <span>
            Region <strong>{region.label}</strong>
          </span>
          <span>
            Weather station{" "}
            <strong>
              {
                _.values(weatherStations).find(
                  ({ value }) => value === values[settingIds.WEATHER_STATION]
                ).label
              }
            </strong>
            <Help help={helps.weatherData} />
          </span>
        </label>
        <div style={{ height: "400px" }}>
          <MapInput
            value={values[settingIds.WEATHER_STATION]}
            onChange={({ weatherStation: value }) =>
              onChange(
                this.onChange(value, scenarios),
                _.find(_.values(weatherStations), { value }).label
              )
            }
          />
        </div>
      </div>
    );
  };
}

class SoilSetting extends Setting {
  id = settingIds.SOIL;

  dependsOn = [settingIds.REGION];

  onDependencySettingChange = (values) => {
    const value = values[this.id];
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    if (!_.find(options, { value })) {
      values[this.id] = setting.default(values);
    }
    return values;
  };

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Soil
          <Help help={helps.soilData} />
        </label>
        <select
          id={id}
          aria-label={`${id} setting`}
          value={values[settingIds.SOIL]}
          onChange={(e) =>
            onChange(this.onChange(e.target.value, scenarios), e.target.value)
          }
          className="form-select"
        >
          {regions[values[settingIds.REGION]].settings[settingIds.SOIL]
            .options(values)
            .map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
        </select>
      </div>
    );
  };
}

class PlantingSetting extends Setting {
  id = settingIds.PLANTING;

  dependsOn = [settingIds.REGION];

  onDependencySettingChange = (values) => {
    const value = values[this.id];
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    if (!_.find(options, { value })) {
      values[this.id] = setting.default(values);
    }
    return values;
  };

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Planting scenario
          <Help help={helps.cropFallowCycle(values[settingIds.REGION])} />
        </label>
        <select
          id={id}
          aria-label={`${id} setting`}
          value={values[settingIds.PLANTING]}
          onChange={(e) =>
            onChange(this.onChange(e.target.value, scenarios), e.target.value)
          }
          className="form-select"
        >
          {regions[values[settingIds.REGION]].settings[settingIds.PLANTING]
            .options(values)
            .map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
        </select>
      </div>
    );
  };
}

class TillageSetting extends Setting {
  id = settingIds.TILLAGE;

  dependsOn = [
    settingIds.REGION,
    settingIds.IS_BURNT,
    settingIds.IRRIGATION_SYSTEM,
  ];

  onDependencySettingChange = (values) => {
    let value = values[this.id];
    const isBurnt = values[settingIds.IS_BURNT];
    if (_.isEqual(["T1"], value) && isBurnt) {
      values[this.id] = ["T2"];
    }
    if (_.isEqual(["T2"], value) && !isBurnt) {
      values[this.id] = ["T1"];
    }
    value = values[this.id];
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    if (!_.find(options, (option) => _.isEqual(value, option.value))) {
      values[this.id] = setting.default(values);
    }
    return values;
  };

  render = ({ values, onChange, className, scenarios }) => {
    const id = this.generateUniqueId();
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    const selected = options.find((option) =>
      _.isEqual(values[settingIds.TILLAGE], option.value)
    );
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Tillage scenario
          <Help help={helps.tillage(values[settingIds.REGION])} />
        </label>
        <select
          id={id}
          aria-label={`${id} setting`}
          value={options.indexOf(selected)}
          onChange={(e) =>
            onChange(
              this.onChange(
                options[_.toNumber(e.target.value)].value,
                scenarios
              ),
              options[_.toNumber(e.target.value)].value
            )
          }
          className="form-select"
          disabled={
            scenarios.new.settings[settingIds.IRRIGATION_SYSTEM] ===
              irrigationSystemIds.SUBSURFACE_DRIP ||
            scenarios.current.settings[settingIds.IRRIGATION_SYSTEM] ===
              irrigationSystemIds.SUBSURFACE_DRIP
          }
        >
          {options.map((option) => (
            <option
              key={`${values[settingIds.REGION]}:${JSON.stringify(
                option.value
              )}`}
              value={options.indexOf(option)}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };
}

class IsBurntSetting extends Setting {
  id = settingIds.IS_BURNT;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    const region = regions[values[settingIds.REGION]];
    const setting = region.settings[this.id];
    const isDisabled =
      setting.isDisabled ||
      !_.some([["T1"], ["T2"]], (tillage) =>
        _.isEqual(tillage, values[settingIds.TILLAGE])
      );
    const isChecked = isDisabled
      ? setting.isCheckedWhenDisabled
      : values[settingIds.IS_BURNT];
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Is burnt?
          <Help help={helps.residueBurning} />
        </label>
        <div className="form-check form-switch">
          <input
            id={id}
            aria-label={`${id} setting`}
            disabled={isDisabled}
            checked={isChecked}
            onChange={(e) =>
              onChange(
                this.onChange(!values[settingIds.IS_BURNT], scenarios),
                !values[settingIds.IS_BURNT] ? "true" : "false"
              )
            }
            className="form-check-input"
            type="checkbox"
            role="switch"
          />
          {isChecked ? "Yes" : "No"}
        </div>
      </div>
    );
  };
}

class DryOffDaysSetting extends Setting {
  id = settingIds.DRY_OFF_DAYS;

  dependsOn = [settingIds.REGION, settingIds.IRRIGATION_SYSTEM];

  onDependencySettingChange = (values) => {
    const value = values[this.id];
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    if (values[settingIds.IRRIGATION_SYSTEM] === irrigationSystemIds.RAINFED) {
      values[this.id] = 0;
    } else if (!_.find(options, { value })) {
      values[this.id] = setting.default(values);
    }
    return values;
  };

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Dry off period
        </label>
        <select
          id={id}
          aria-label={`${id} setting`}
          value={values[settingIds.DRY_OFF_DAYS]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toInteger(e.target.value), scenarios),
              _.toInteger(e.target.value)
            )
          }
          className="form-select"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };
}

class FertiliserAmountSetting extends Setting {
  id = settingIds.FERTILISER_AMOUNT;

  dependsOn = [settingIds.REGION];

  onDependencySettingChange = (values) => {
    const value = values[this.id];
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    if (!_.find(options, { value })) {
      values[this.id] = setting.default(values);
    }
    return values;
  };

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    const region = regions[values[settingIds.REGION]];
    const options = region.settings[this.id].options(values);
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          N applied
          <Help help={helps.nFertilisation(region.id)} />
        </label>
        <select
          id={id}
          aria-label={`${id} setting`}
          value={values[this.id]}
          onChange={(e) => {
            let value = e.target.value;
            if (_.isFinite(Number(e.target.value))) {
              value = _.toInteger(e.target.value);
            }
            onChange(this.onChange(value, scenarios), value);
          }}
          className="form-select"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };
}

class FertiliserDistributionSetting extends Setting {
  dependsOn = [settingIds.REGION, settingIds.IRRIGATION_SYSTEM];

  onDependencySettingChange = (values) => {
    const value = values[this.id];
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    if (!_.find(options, { value })) {
      values[settingIds.FERTILISER_DISTRIBUTION] = setting.default(values);
    }
    return values;
  };

  isVisible = (values) => {
    const region = regions[values[settingIds.REGION]];
    const setting = region.settings[this.id];
    return setting.isVisible;
  };

  onChange = (value, scenarios) =>
    produce(scenarios, (draft) => {
      const dependants = this.getDependents();
      _.toPairs(draft).forEach(([name, scenario]) => {
        /*
          @TODO: If Mackay ends up using a secondary fertiliser distribution setting then only update appropriate scenarios here.
          Most likely you'll want to filter based on scenario.settings[settingIds.IRRIGATION_SYSTEM].
        */
        const setting =
          regions[scenario.settings[settingIds.REGION]].settings[this.id];
        const options = setting.options(scenario.settings);
        if (_.find(options, { value })) {
          scenario.settings[settingIds.FERTILISER_DISTRIBUTION] = value;
        }
        dependants.forEach((dependant) =>
          dependant.onDependencySettingChange(
            scenario.settings,
            scenarios[name].settings,
            name
          )
        );
      });
    });

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          {setting.label}
        </label>
        <select
          id={id}
          aria-label={`${id} setting`}
          value={values[this.id]}
          onChange={(e) => {
            const value = e.target.value;
            onChange(this.onChange(value, scenarios), value);
          }}
          className="form-select"
          disabled={setting.isDisabled(scenarios)}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };
}

class PrimaryFertiliserDistributionSetting extends FertiliserDistributionSetting {
  id = settingIds.FERTILISER_DISTRIBUTION;
}

class SecondaryFertiliserDistributionSetting extends FertiliserDistributionSetting {
  id = settingIds.SECONDARY_FERTILISER_DISTRIBUTION;
}

class FallowSetting extends Setting {
  id = settingIds.FALLOW;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Fallow
        </label>
        <select
          id={id}
          aria-label={`${id} setting`}
          value={values[settingIds.FALLOW]}
          onChange={(e) =>
            onChange(this.onChange(e.target.value, scenarios), e.target.value)
          }
          className="form-select"
          disabled
        >
          <option value="BareFallow">Bare fallow</option>
        </select>
      </div>
    );
  };
}

class RatoonSetting extends Setting {
  id = settingIds.RATOON;

  dependsOn = [settingIds.REGION];

  onDependencySettingChange = (values) => {
    const value = values[this.id];
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    if (!_.find(options, { value })) {
      values[this.id] = setting.default();
    }
    return values;
  };

  render = ({ values, scenarios, onChange, className }) => {
    const options =
      regions[values[settingIds.REGION]].settings[this.id].options();
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Display results
        </label>
        <select
          id={id}
          aria-label={`${id} setting`}
          value={values[settingIds.RATOON]}
          onChange={(e) => {
            const ratoon = e.target.value;
            let newValue = ratoon;
            if (ratoon !== "ALL") {
              newValue = _.toInteger(ratoon);
            }
            onChange(this.onChange(newValue, scenarios), newValue);
          }}
          className="form-select"
        >
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
    );
  };
}

class HarvestYearsSetting extends Setting {
  id = settingIds.HARVEST_YEARS;

  dependsOn = [settingIds.REGION];

  onDependencySettingChange = (values) => {
    const { start, end } = values[this.id];
    const { min, max } = regions[values[settingIds.REGION]].settings[this.id];
    if (start < min || end > max) {
      values[this.id] = {
        start: _.max([start, min]),
        end: _.min([end, max]),
      };
    }
    return values;
  };
  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    const handleStyle = {
      backgroundColor: COLOR_PRIMARY,
      height: "17px",
      width: "17px",
      borderWidth: 0,
      opacity: 1,
      marginTop: "-4px",
    };
    const value = values[settingIds.HARVEST_YEARS];
    const { min, max } = regions[values[settingIds.REGION]].settings[this.id];
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Harvest years
          <strong>{` ${value.start} to ${value.end}`}</strong>
        </label>
        <div className="mx-2 pb-3 irat-paddock-settings__slider">
          <Slider
            id={id}
            aria-label={`${id} setting`}
            onChange={(years) =>
              onChange(
                this.onChange(
                  {
                    start: years[0],
                    end: years[1],
                  },
                  scenarios
                ),
                `${years[0]} - ${years[1]}`
              )
            }
            style={{ height: "12px" }}
            range
            min={min}
            max={max}
            value={[value.start, value.end]}
            handleStyle={[handleStyle, handleStyle]}
            trackStyle={[{ backgroundColor: COLOR_PRIMARY, height: "9px" }]}
            railStyle={{
              backgroundColor: COLOR_GRAY_300,
              height: "9px",
            }}
          />
        </div>
      </div>
    );
  };

  permission = CHANGE_ADVANCED_SETTINGS;
}

class PaddockSizeSetting extends Setting {
  id = settingIds.PADDOCK_SIZE;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Paddock size
          <strong>{` ${values[settingIds.PADDOCK_SIZE]} ha`}</strong>
        </label>
        <input
          type="range"
          min={1}
          max={200}
          value={values[settingIds.PADDOCK_SIZE]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toInteger(e.target.value), scenarios),
              _.toInteger(e.target.value)
            )
          }
          className="form-range"
        />
      </div>
    );
  };
}

class WaterCostSetting extends Setting {
  id = settingIds.WATER_COST;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Water cost
          <strong>{` $${values[settingIds.WATER_COST]} / ML`}</strong>
        </label>
        <input
          id={id}
          aria-label={`${id} setting`}
          type="range"
          min={0}
          max={200}
          value={values[settingIds.WATER_COST]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toInteger(e.target.value), scenarios),
              _.toInteger(e.target.value)
            )
          }
          className="form-range"
        />
      </div>
    );
  };
}

class SurfaceEnergyUseSetting extends Setting {
  id = settingIds.SURFACE_ENERGY_USED;

  dependsOn = [settingIds.IRRIGATION_SYSTEM];

  onDependencySettingChange = (values, previous) => {
    const previousSystem = previous[settingIds.IRRIGATION_SYSTEM];
    const nextSystem = values[settingIds.IRRIGATION_SYSTEM];
    if (nextSystem !== previousSystem) {
      if (nextSystem === irrigationSystemIds.FURROW) {
        values[this.id] = 70;
      } else {
        values[this.id] = 120;
      }
    }
    return values;
  };

  isVisible = (values) =>
    !_.includes(
      [irrigationSystemIds.SUBSURFACE_DRIP, irrigationSystemIds.RAINFED],
      values[settingIds.IRRIGATION_SYSTEM]
    );

  render = ({ values, onChange, className, scenarios }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Energy used
          <strong>{` ${
            values[settingIds.SURFACE_ENERGY_USED]
          }kWh / ML`}</strong>
        </label>
        <input
          id={id}
          aria-label={`${id} setting`}
          type="range"
          min={0}
          max={400}
          value={values[settingIds.SURFACE_ENERGY_USED]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toInteger(e.target.value), scenarios),
              _.toInteger(e.target.value)
            )
          }
          className="form-range"
        />
      </div>
    );
  };
}

class DripEnergyUsedSetting extends Setting {
  id = settingIds.DRIP_ENERGY_USED;

  isVisible = (values) => {
    const region = regions[values[settingIds.REGION]];
    const setting = region.settings[this.id];
    return (
      setting.isVisible &&
      values[settingIds.IRRIGATION_SYSTEM] ===
        irrigationSystemIds.SUBSURFACE_DRIP
    );
  };

  render = ({ values, onChange, className, scenarios }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Energy used
          <strong>{` ${values[settingIds.DRIP_ENERGY_USED]}kWh / ML`}</strong>
        </label>
        <input
          id={id}
          aria-label={`${id} setting`}
          type="range"
          min={0}
          max={400}
          value={values[settingIds.DRIP_ENERGY_USED]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toInteger(e.target.value), scenarios),
              _.toInteger(e.target.value)
            )
          }
          className="form-range"
        />
      </div>
    );
  };
}

class EnergyCostSetting extends Setting {
  id = settingIds.ENERGY_COST;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Energy cost
          <strong>{` $${values[settingIds.ENERGY_COST]} / kWh`}</strong>
        </label>
        <input
          id={id}
          aria-label={`${id} setting`}
          type="range"
          step={0.005}
          min={0}
          max={0.5}
          value={values[settingIds.ENERGY_COST]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toNumber(e.target.value), scenarios),
              _.toNumber(e.target.value)
            )
          }
          className="form-range"
        />
      </div>
    );
  };
}

class SurfaceOperationCostSetting extends Setting {
  id = settingIds.SURFACE_OPERATION_COST;

  render = ({ values, onChange, className, scenarios }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Operation cost (furrow)
          <strong>{` $${
            values[settingIds.SURFACE_OPERATION_COST]
          } / irrigation`}</strong>
        </label>
        <input
          id={id}
          aria-label={`${id} setting`}
          type="range"
          min={0}
          max={500}
          step={25}
          value={values[settingIds.SURFACE_OPERATION_COST]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toInteger(e.target.value), scenarios),
              _.toInteger(e.target.value)
            )
          }
          className="form-range"
          disabled={
            scenarios.new.settings[settingIds.IRRIGATION_SYSTEM] ===
              irrigationSystemIds.SUBSURFACE_DRIP &&
            scenarios.current.settings[settingIds.IRRIGATION_SYSTEM] ===
              irrigationSystemIds.SUBSURFACE_DRIP
          }
        />
      </div>
    );
  };
  permission = CHANGE_ADVANCED_SETTINGS;
}

class DripOperationCostSetting extends Setting {
  id = settingIds.DRIP_OPERATION_COST;

  render = ({ values, onChange, className, scenarios }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Operation cost (pressurised)
          <strong>{` $${
            values[settingIds.DRIP_OPERATION_COST]
          } / irrigation`}</strong>
        </label>
        <input
          id={id}
          aria-label={`${id} setting`}
          type="range"
          min={0}
          max={200}
          step={25}
          value={values[settingIds.DRIP_OPERATION_COST]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toInteger(e.target.value), scenarios),
              _.toInteger(e.target.value)
            )
          }
          className="form-range"
          disabled={
            scenarios.new.settings[settingIds.IRRIGATION_SYSTEM] ===
              irrigationSystemIds.FURROW &&
            scenarios.current.settings[settingIds.IRRIGATION_SYSTEM] ===
              irrigationSystemIds.FURROW
          }
        />
      </div>
    );
  };

  permission = CHANGE_ADVANCED_SETTINGS;
}

class FuleSetting extends Setting {
  id = settingIds.FUEL;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Fuel used for generating electricity
        </label>
        <select
          id={id}
          aria-label={`${id} setting`}
          value={values[settingIds.FUEL]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toInteger(e.target.value), scenarios),
              _.toInteger(e.target.value)
            )
          }
          className="form-select"
        >
          <option value="98300">Anthracite (Hard/Black Coal)</option>
          <option value="101000">Brown Coal (Lignite)</option>
          <option value="94600">Coking Coal</option>
          <option value="73300">Crude Oil</option>
          <option value="69300">Motor Gasoline</option>
          <option value="74100">Gas/Diesel Oil</option>
          <option value="15600">Natural Gas</option>
        </select>
      </div>
    );
  };

  permission = CHANGE_ADVANCED_SETTINGS;
}

class HarvestLossSetting extends Setting {
  id = settingIds.HARVEST_LOSS;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Harvest loss
          <strong>{` ${values[settingIds.HARVEST_LOSS]}%`}</strong>
        </label>
        <input
          id={id}
          aria-label={`${id} setting`}
          type="range"
          min={0}
          max={30}
          value={values[settingIds.HARVEST_LOSS]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toInteger(e.target.value), scenarios),
              _.toInteger(e.target.value)
            )
          }
          className="form-range"
        />
      </div>
    );
  };
  permission = CHANGE_ADVANCED_SETTINGS;
}

class SugarPriceSetting extends Setting {
  id = settingIds.SUGAR_PRICE;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Sugar price
          <strong>{` $${values[settingIds.SUGAR_PRICE]} / IPS t`}</strong>
        </label>
        <input
          id={id}
          aria-label={`${id} setting`}
          type="range"
          min={300}
          max={600}
          value={values[settingIds.SUGAR_PRICE]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toInteger(e.target.value), scenarios),
              _.toInteger(e.target.value)
            )
          }
          className="form-range"
        />
      </div>
    );
  };

  permission = CHANGE_ADVANCED_SETTINGS;
}

class RelativeCssSetting extends Setting {
  id = settingIds.RELATIVE_CCS;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Relative CSS
          <strong>{` ${values[settingIds.RELATIVE_CCS]}%`}</strong>
        </label>
        <input
          id={id}
          aria-label={`${id} setting`}
          type="range"
          step={0.5}
          min={0}
          max={20}
          value={values[settingIds.RELATIVE_CCS]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toNumber(e.target.value), scenarios),
              _.toNumber(e.target.value)
            )
          }
          className="form-range"
        />
      </div>
    );
  };

  permission = CHANGE_ADVANCED_SETTINGS;
}

class CanePriceConstantSetting extends Setting {
  id = settingIds.CANE_PRICE_CONSTANT;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Cane price constant
          <strong>{` $${values[settingIds.CANE_PRICE_CONSTANT]} / t`}</strong>
        </label>
        <input
          id={id}
          aria-label={`${id} setting`}
          type="range"
          step={0.1}
          min={0}
          max={1}
          value={values[settingIds.CANE_PRICE_CONSTANT]}
          onChange={(e) =>
            onChange(
              this.onChange(_.toNumber(e.target.value), scenarios),
              _.toNumber(e.target.value)
            )
          }
          className="form-range"
        />
      </div>
    );
  };

  permission = CHANGE_ADVANCED_SETTINGS;
}

class IrrigationSystemSetting extends Setting {
  id = settingIds.IRRIGATION_SYSTEM;

  dependsOn = [settingIds.REGION];

  onDependencySettingChange = (values, previous) => {
    const value = values[this.id];
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    if (!_.find(options, { value })) {
      values[this.id] = setting.default(values);
    }
    return values;
  };

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    const value = values[this.id];
    const region = regions[values[settingIds.REGION]];
    const options = region.settings[this.id].options(values);
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Irrigation system
          <Help help={helps.irrigationSystems(region.id)} />
        </label>
        <select
          id={id}
          aria-label={`${id} setting`}
          value={value}
          onChange={(e) => {
            onChange(this.onChange(e.target.value, scenarios), e.target.value);
          }}
          className="form-select"
          disabled={options.length < 2}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };
}

class IrrigationFrequencySetting extends Setting {
  id = settingIds.IRRIGATION_FREQUENCY;

  dependsOn = [settingIds.REGION, settingIds.IRRIGATION_SYSTEM];

  onDependencySettingChange = (values) => {
    const value = values[this.id];
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    if (values[settingIds.IRRIGATION_SYSTEM] === irrigationSystemIds.RAINFED) {
      values[this.id] = "None";
    } else if (!_.find(options, { value })) {
      values[this.id] = setting.default(values);
    }
    return values;
  };

  isVisible = (values) =>
    values[settingIds.IRRIGATION_SYSTEM] !== irrigationSystemIds.RAINFED;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    const region = regions[values[settingIds.REGION]];
    const options =
      region.settings[settingIds.IRRIGATION_FREQUENCY].options(values);
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Irrigation strategy
          <Help help={helps.irrigationStrategy(region.id)} />
        </label>
        <select
          id={id}
          aria-label={`${id} setting`}
          value={values[settingIds.IRRIGATION_FREQUENCY]}
          onChange={(e) =>
            onChange(this.onChange(e.target.value, scenarios), e.target.value)
          }
          className="form-select"
        >
          {options.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    );
  };
}

class IrrigationAllocationSetting extends Setting {
  id = settingIds.IRRIGATION_ALLOCATION;

  dependsOn = [settingIds.REGION, settingIds.IRRIGATION_SYSTEM];

  onDependencySettingChange = (values) => {
    const value = values[this.id];
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    if (values[settingIds.IRRIGATION_SYSTEM] === irrigationSystemIds.RAINFED) {
      values[this.id] = 0;
    } else if (!_.find(options, { value })) {
      values[this.id] = setting.default(values);
    }
    return values;
  };

  isVisible = (values) =>
    values[settingIds.IRRIGATION_SYSTEM] !== irrigationSystemIds.RAINFED;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    const selected = _.find(options, {
      value: values[this.id],
    });
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          Water allocation
          <strong>{` ${selected.label} ML/ha`}</strong>
        </label>
        <input
          id={id}
          aria-label={`${id} setting`}
          type="range"
          className="form-range"
          min={0}
          max={options.length - 1}
          value={options.indexOf(selected)}
          onChange={(e) =>
            onChange(
              this.onChange(
                options[_.toInteger(e.target.value)].value,
                scenarios
              ),
              options[_.toInteger(e.target.value)].value
            )
          }
          disabled={setting.isDisabled}
        />
      </div>
    );
  };
}

class IrrigationAmountSetting extends Setting {
  id = settingIds.IRRIGATION_AMOUNT;

  dependsOn = [
    settingIds.REGION,
    settingIds.IRRIGATION_ALLOCATION,
    settingIds.IRRIGATION_SYSTEM,
  ];

  onDependencySettingChange = (values, previous, name) => {
    const setting = regions[values[settingIds.REGION]].settings[this.id];
    const options = setting.options(values);
    const travellerSystemIds = [
      irrigationSystemIds.Traveller_15_0,
      irrigationSystemIds.Traveller_15_10,
      irrigationSystemIds.Traveller_15_25,
    ];
    const overheadSystemIds = [
      irrigationSystemIds.LPOH_5_0,
      irrigationSystemIds.LPOH_5_10,
      irrigationSystemIds.LPOH_5_20,
    ];
    const hasChangedFromFurrowToOverheadOrTraveller =
      previous[settingIds.IRRIGATION_SYSTEM] === irrigationSystemIds.FURROW &&
      _.includes(
        [...overheadSystemIds, ...travellerSystemIds],
        values[settingIds.IRRIGATION_SYSTEM]
      );

    if (hasChangedFromFurrowToOverheadOrTraveller) {
      values[this.id] = _.get(_.find(options, { label: 6 }), "value");
    }
    if (values[settingIds.IRRIGATION_SYSTEM] === irrigationSystemIds.RAINFED) {
      values[this.id] = 0;
    } else if (!_.find(options, { value: values[this.id] })) {
      values[this.id] = setting.default(values);
    }
    return values;
  };

  isVisible = (values) =>
    values[settingIds.IRRIGATION_SYSTEM] !== irrigationSystemIds.RAINFED;

  render = ({ values, scenarios, onChange, className }) => {
    const id = this.generateUniqueId();
    let label;
    const region = regions[values[settingIds.REGION]];
    const options = region.settings[this.id].options(values);
    if (values[settingIds.IRRIGATION_ALLOCATION] === 999) {
      label = (
        <>
          Water applied per irrigation
          <strong>{` ${values[this.id]}mm`}</strong>
        </>
      );
    } else {
      const selected = _.find(options, {
        value: values[this.id],
      });
      label = (
        <>
          Maximum number of irrigations
          <strong> {_.get(selected, "label")}</strong>
        </>
      );
    }
    return (
      <div className={className}>
        <label htmlFor={id} className="form-label">
          {label}
        </label>
        <input
          id={id}
          aria-label={`${id} setting`}
          type="range"
          className="form-range"
          min={0}
          max={options.length - 1}
          value={_.map(options, "value").indexOf(values[this.id])}
          onChange={(e) =>
            onChange(
              this.onChange(
                _.map(options, "value")[_.toInteger(e.target.value)],
                scenarios
              ),
              _.map(options, "value")[_.toInteger(e.target.value)]
            )
          }
        />
      </div>
    );
  };
}

export const regionSetting = new RegionSetting();
export const weatherStationSetting = new WeatherStationSetting();
export const soilSetting = new SoilSetting();
export const plantingSetting = new PlantingSetting();
export const tillageSetting = new TillageSetting();
export const isBurntSetting = new IsBurntSetting();
export const dryOffDaysSetting = new DryOffDaysSetting();
export const fertiliserAmountSetting = new FertiliserAmountSetting();
export const primaryFertiliserDistributionSetting =
  new PrimaryFertiliserDistributionSetting();
export const secondaryFertiliserDistributionSetting =
  new SecondaryFertiliserDistributionSetting();
export const fallowSetting = new FallowSetting();
export const ratoonSetting = new RatoonSetting();
export const harvestYearsSetting = new HarvestYearsSetting();
export const paddockSizeSetting = new PaddockSizeSetting();
export const waterCostSetting = new WaterCostSetting();
export const energyCostSetting = new EnergyCostSetting();
export const surfaceOperationCostSetting = new SurfaceOperationCostSetting();
export const dripOperationCostSetting = new DripOperationCostSetting();
export const fuleSetting = new FuleSetting();
export const harvestLossSetting = new HarvestLossSetting();
export const sugarPriceSetting = new SugarPriceSetting();
export const relativeCssSetting = new RelativeCssSetting();
export const canePriceConstantSetting = new CanePriceConstantSetting();
export const irrigationSystemSetting = new IrrigationSystemSetting();
export const irrigationFrequencySetting = new IrrigationFrequencySetting();
export const irrigationAllocationSetting = new IrrigationAllocationSetting();
export const irrigationAmountSetting = new IrrigationAmountSetting();
export const surfaceEnergyUseSetting = new SurfaceEnergyUseSetting();
export const dripEnergyUsedSetting = new DripEnergyUsedSetting();

export const paddockSettings = [
  regionSetting,
  weatherStationSetting,
  soilSetting,
  plantingSetting,
  tillageSetting,
  isBurntSetting,
  dryOffDaysSetting,
  fertiliserAmountSetting,
  primaryFertiliserDistributionSetting,
  secondaryFertiliserDistributionSetting,
  fallowSetting,
  ratoonSetting,
  harvestYearsSetting,
  paddockSizeSetting,
  waterCostSetting,
  energyCostSetting,
  surfaceOperationCostSetting,
  dripOperationCostSetting,
  fuleSetting,
  harvestLossSetting,
  sugarPriceSetting,
  relativeCssSetting,
  canePriceConstantSetting,
];

export const scenarioSettings = [
  irrigationSystemSetting,
  irrigationFrequencySetting,
  irrigationAllocationSetting,
  irrigationAmountSetting,
  surfaceEnergyUseSetting,
  dripEnergyUsedSetting,
];

const settings = [...paddockSettings, ...scenarioSettings];
